<template>
  <v-card flat class="card-confirmacao">
    <div class="barra-azul"></div>

    <div class="conteudo-card">
      <div class="top-card">
        <div class="title">
          <span>
            Seu plano está <br />
            cancelado!
          </span>
        </div>

        <div class="confirmacao-texto">
          <img src="@/assets/icons/check-circle-solid_icon.svg" alt="" />
          <span> Cancelamento confirmado </span>
        </div>

        <div class="descricao">
          <span>
            Você receberá um e-mail confirmando que o seu plano atual foi
            cancelado. Lamentamos o ocorrido e esperamos que você volte a ter um
            de nossos planos. Estamos a disposição para dúvidas.
          </span>
        </div>
      </div>

      <div class="card-produto-cancelado">
        <!-- <figure></figure> -->

        <div class="descricoes">
          <div class="nome-plano m-top-botto{">
            <span> Plano Kristal{{title}} </span>
          </div>
          <div class="descricao-produto m-top-bottom">
            <span> Descrição do produto. Se é mensal, anual, e etc. </span>
          </div>

          <div class="empresa">
            <span>Kristal Connect</span>
          </div>

          <div class="valor">
            <span> {{preco}} </span>
          </div>

          <div class="tipo-pagamento">
            <span> Pagamento à vista </span>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data(){
    return{
      preco: '',
      title: ''
    }
  },
  created(){
    this.preco = this.$route.params.preco
    this.title = this.$route.params.title
  }
};
</script>

<style  scoped>
.card-confirmacao {
  border: 1px solid #dadada;
  border-radius: 7px;
  padding: 2rem;
  position: relative;
}
.barra-azul {
  position: absolute;
  padding: 0.3rem;
  background: var(--principal-azul);
  top: 0;
  right: 0;
  left: 0;
}

/* .conteudo-card {
} */

/* TOPO DO CARD */
.top-card {
  text-align: center;
}
.top-card > div {
  margin: 0.5rem 0;
  padding: 0.5rem;
}
.top-card .title span {
  font-weight: 700;
  color: var(--principal-azul);
}
.top-card .confirmacao-texto {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}
.confirmacao-texto img {
  width: 25px;
  margin: 0 0.2rem;
}
.confirmacao-texto span {
  font-weight: 500;
}
.top-card .descricao {
  color: #707070;
  font-size: 0.8em;
  width: 50%;
  margin: 0 auto;
  transition: 0.2s ease-in-out;
}
/* FIM DO TOPO DO CARD */

.card-produto-cancelado {
  width: 50%;
  margin: 1.5rem auto;
  transition: 0.2s ease-in-out;
  padding: 1.5rem;
  background: rgba(164, 164, 164, 0.4);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.m-top-bottom {
  margin: 0.5rem 0;
}
.descricoes .nome-plano {
  font-weight: 500;
  font-size: 1.1em;
  line-height: 124.4%;

  color: #000000;
}
.descricoes .descricao-produto {
  font-weight: 300;
  font-size: 0.8em;
  line-height: 124.4%;
  color: #5f6368;
}
.descricoes .empresa {
  margin-top: 1.8rem;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 124.4%;
  color: #000000;
}
.descricoes .valor {
  font-weight: normal;
  font-size: 1.5em;
  line-height: 124.4%;
  color: #000000;
}
.descricoes .tipo-pagamento{
    font-weight: bold;
font-size: .9em;
line-height: 124.4%;
color: #B9B9B9;
}

@media screen and (max-width: 700px) {
  .top-card > div {
    margin: 0.8rem 0;
  }
  .top-card .descricao {
    width: 80%;
  }
  .card-produto-cancelado {
    width: 80%;
  }
}
</style>