var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "card-confirmacao", attrs: { flat: "" } },
    [
      _c("div", { staticClass: "barra-azul" }),
      _c("div", { staticClass: "conteudo-card" }, [
        _c("div", { staticClass: "top-card" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", [
              _vm._v(" Seu plano está "),
              _c("br"),
              _vm._v(" cancelado! "),
            ]),
          ]),
          _c("div", { staticClass: "confirmacao-texto" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/check-circle-solid_icon.svg"),
                alt: "",
              },
            }),
            _c("span", [_vm._v(" Cancelamento confirmado ")]),
          ]),
          _c("div", { staticClass: "descricao" }, [
            _c("span", [
              _vm._v(
                " Você receberá um e-mail confirmando que o seu plano atual foi cancelado. Lamentamos o ocorrido e esperamos que você volte a ter um de nossos planos. Estamos a disposição para dúvidas. "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "card-produto-cancelado" }, [
          _c("div", { staticClass: "descricoes" }, [
            _c("div", { staticClass: "nome-plano m-top-botto{" }, [
              _c("span", [_vm._v(" Plano Kristal" + _vm._s(_vm.title) + " ")]),
            ]),
            _c("div", { staticClass: "descricao-produto m-top-bottom" }, [
              _c("span", [
                _vm._v(" Descrição do produto. Se é mensal, anual, e etc. "),
              ]),
            ]),
            _c("div", { staticClass: "empresa" }, [
              _c("span", [_vm._v("Kristal Connect")]),
            ]),
            _c("div", { staticClass: "valor" }, [
              _c("span", [_vm._v(" " + _vm._s(_vm.preco) + " ")]),
            ]),
            _c("div", { staticClass: "tipo-pagamento" }, [
              _c("span", [_vm._v(" Pagamento à vista ")]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }