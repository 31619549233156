<template>
  <div class="confirmacao-cancelamento">
    <CardCancelar class="item" :userInfo="user" :planInfo="planInfo" />
    <CardConfirmacaoCancelamento class="item"/>
  </div>
</template>

<script>
import TiposPlanos from '../../../../services/TiposPlanos'
import CardCancelar from "../components/CardCancelarPlano.vue";
import CardConfirmacaoCancelamento from '../components/CardConfirmacaoCancelamento.vue'
export default {
  components: {
    CardCancelar,
    CardConfirmacaoCancelamento
  },
   data(){
      return{
          user: undefined,
          planInfo: undefined,
          tiposPlanos: new TiposPlanos()
      }
  },
  created() {
    this.user = this.$store.getters.$userLogged;
    this.planInfo = this.tiposPlanos.SelecionarPlano(this.user.planType)
  },
};
</script>

<style  scoped>
.item {
  margin: 1.5rem 0;
}
</style>